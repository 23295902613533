
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"clip-rule":"evenodd","d":"M.22.22a.75.75 0 011.06 0L6 4.94 10.72.22a.75.75 0 111.06 1.06L7.06 6l4.72 4.72a.75.75 0 11-1.06 1.06L6 7.06l-4.72 4.72a.75.75 0 01-1.06-1.06L4.94 6 .22 1.28a.75.75 0 010-1.06z"}})])
          )
        }
      }
    