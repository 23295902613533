
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 16 10"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{staticClass:"st0",attrs:{"d":"M8 2c-.3 0-.6 0-.9.1.1.2.2.4.2.7 0 .8-.7 1.4-1.6 1.4-.2.1-.4 0-.7-.1-.4 1.5.5 3.1 2.1 3.5 1.7.4 3.4-.4 3.9-1.9s-.5-3.1-2.1-3.5C8.6 2.1 8.3 2 8 2zm7.9 2.5C14.4 1.8 11.4 0 8 0S1.6 1.8.1 4.5c-.1.2-.1.5 0 .8C1.6 7.9 4.6 9.8 8 9.8s6.4-1.8 7.9-4.5c.1-.3.1-.6 0-.8zM8 8.5c-2.7 0-5.3-1.4-6.6-3.7C2.7 2.6 5.3 1.2 8 1.2s5.3 1.4 6.6 3.7C13.3 7.1 10.7 8.5 8 8.5z"}})])
          )
        }
      }
    